html, body {
	height: 100%; }
body {
	background: #fff;

	// responsive
	// +css-lock(16, 50, 600, 1000)
	@include text16;
	font-family: $font-primary;
	font-weight: normal;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	-webkit-font-smoothing: antialiased; }

@mixin hOp {
	transition: opacity .3s ease;
	&:hover {
			opacity: 0.8; } }

.header {
	&-absolute {
		position: absolute;
		background: transparent;
		border-bottom: 0 !important;
		@include r(767) {
			display: none; } }
	&-fixed {
		position: fixed;
		z-index: 40;
		padding: 0.9rem 0;
		transition: all .4s ease;
		top: -100%;
		opacity: 0;
		visibility: hidden;
		@include r(767) {
			padding: 0.7rem 0 !important;
			opacity: 1 !important;
			visibility: visible !important;
			top: 0 !important; } }
	background: #ED0973;
	border-bottom: 1px solid  #ffffff;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	padding: 1.3rem 0;
	@include r(767) {
		padding: 1rem 0; }
	&-wrapper {
		width: 100%; }
	&-logo {
		display: inline-block;
		max-width: 80px;
		img {
			max-width: 100%; }
		@include hOp; }
	&-controls {
		display: flex;
		flex-wrap: wrap;
		margin-left: -1.03125rem;
		margin-right: -10px;
		&-group {
			padding-left: 1.03125rem;
			padding-right: 1.03125rem;
			&:last-child {
				padding-right: 10px; } } }
	&-sign {
		margin-bottom: 0.5rem;
		margin-left: 0.7rem;
		display: inline-block; }

	&-nav {
		&-list {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 0;
			&-item {
				&__link {
					color: #ffffff;
					@include text14;
					margin-right: 1.1rem;
					@include r(1339) {
						margin-right: 0.6rem; }
					@include r(1269) {
						font-size: 12px; }
					transition: all .15s ease;
					&:hover {
						color: #fff !important;
						opacity: 0.8; } }

				&:last-child a {
					margin-right: 0; } } } } }

.footer {
	background: #111111;
	&-top {
		border-bottom: 1px solid rgba(96, 96, 96, 0.32);
		padding-top: 1.8rem;
		padding-bottom: .9rem;
		@include r(767) {
			padding-top: 1.6rem;
			padding-bottom: .2rem;
			.text18 {
				br {
					display: none; } } } }
	&-bottom {
		padding-top: 1.7rem;
		padding-bottom: 1.8rem;
		@include r(767) {
			text-align: center;
			padding-top: 1.2rem; } }
	&-logo {
		max-width: 167px;
		img {
			max-width: 100%; }
		@include r(1199) {
			max-width: 105px; }
		@include r(991) {
			max-width: 95px; }
		@include r(767) {
			max-width: 69px; } }
	&-social {
		display: flex;
		justify-content: flex-end;
		@include r(767) {
			justify-content: center; }
		&-item {
			width: 30px;
			height: 30px;
			@include r(991) {
				width: 25px;
				height: 25px; }
			img {
				max-width: 100%;
				max-height: 100%; } } } }

.mobile-menu {
	&-toggle {
		display: inline-block;
		span {
			display: block;
			width: 27px;
			height: 3px;
			background: #ffffff;
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0; } }
		&:focus,
		&:active,
		&:hover {
			// margin-bottom: -1px
			// span
 } }			// 	margin-bottom: 6px
	.menu-overlay {
		opacity: 0;
		background: rgba(0,0,0,0.3);
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		transition: opacity .8s ease;
		visibility: hidden;
		z-index: 1; }
	&.openMenu {
		.menu-overlay {
			opacity: 1;
			visibility: visible; } }
	&-list {
		width: 0%;
		max-width: 217px;
		z-index: 2;
		height: 100%;
		background: #ffffff;
		position: fixed;
		left: 0;
		top: 0;
		overflow: hidden;
		transition: width .4s ease;
		display: block;
		.menu-logo {
			max-width: 67px;
			min-width: 67px;
			display: block;
			margin-bottom: 2rem;
			img {
				max-width: 100%; } }

		// display: none
		&.openMenu {
			width: 100%; } }
	&-content {
		padding: 20px 15px;
		&-nav {
			min-width: 200px;
			&-item {
				display: block;
				margin-bottom: 1rem;
				@include text14;
				color: #000000; } } } }

.main {
	// padding: 20px 0 40px
	flex: 1; }


.navigate {
	&-block {
		padding-right: 2.2rem; }
	&-list {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 0;
		&-item {
			&__link {
				text-transform: uppercase;
				text-decoration: none !important;
				@include text16;
				@include hOp; } } } }

.block {
	&-head {
		padding: 5.85rem 0 2.5rem; } }

// .helpers
.in-dark {
	& > div {
		color: #ffffff; }
	.btn-outline-secondary {
		svg.icon {
			fill: #fff; }
		&:hover {
			svg.icon {
				fill: #000; } } }
	.hr-short {
  	border-top: 1px solid #ffffff; }
	.btn-secondary {
		&:hover {
			background: #ffffff;
			border-color: #ffffff;
			color: $primary; } }
	a:not(.btn) {
		color: #ffffff;
		@include hOp;
		&:hover {
			color: darken(#ffffff, 15%); } }
	.select-lang {
		color: #ffffff !important;
		&:hover {
			color: #000000 !important; } } }
.select-lang {
	transition: all .15s ease !important;
	min-width: 70px !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	.flag {
		justify-content: center; }
	.text {
		display: flex;
		align-items: center;
		margin-bottom: -3px; } }
.in-dark .select-lang {
	&:hover, &.active {
		color: #000000 !important; } }
.select-lang {
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 5px;
	.menu {
		left: 50% !important;
		transform: translateX(-50%);
		top: calc(100% + 10px) !important;
		border-radius: 5px !important; } }
.select-lang.ui .menu>.item {
	min-width: 70px;
	text-align: center;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	padding: 0.475rem 0.65rem !important;
	color: rgba(0,0,0,0.3) !important;
	&.active {
		color: #000000 !important; } }


.first-screen {
	align-items: center;
	padding: 10rem 0; }

.modal-block {
	width: 420px;
	// height: 495px
	margin: 0 auto;
	color: #000000;
	padding: 1.8rem;
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 15px; }
.modal-head {
	padding-top: 0.3rem; }
.form-policy {
	a {
		text-decoration: underline; } }


// elements

.flag {
	display: flex !important;
	align-items: center;
	img {
		width: 20px !important;
		height: 14px !important;
		display: inline-block;
		vertical-align: top;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		max-height: 14px !important;
		margin-left: 0 !important;
		margin-right: 8px !important; } }

.circle {
	display: inline-block;
	border-radius: 50%;
	&-sm {
		width: .5rem;
		height: .5rem;
		margin: 0 4px; }
	&-md {
		width: 11px;
		height: 11px;
		margin: 0 6px; }
	&-lg {
		width: 15px;
		height: 15px;
		margin: 0 10px; } }

.btn-icon {
	display: inline-flex;
	align-items: center;
	svg {
		max-height: 14px;
		max-width: 14px;
		margin-right: 0.3rem;
		transition: fill .15s ease; } }

// controls
.btn {
	box-shadow: none !important;
	min-width: 125px; }
.btn-min {
	min-width: 150px; }
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
	color: #fff;
	background-color: #ee2360;
	border-color: #ee2360; }

.col-20 {
	max-width: 20%;
	flex-basis: 20%;
	position: relative;
	padding-right: 10px;
	padding-left: 10px; }


// thematic colors
[data-filter] {
	&.mixitup-control-active, &:hover {
		.thematic-color {
			background: #fff !important; } }
	.circle {
		display: inline-flex;
		align-items: center;
		transition: background .15s ease; } }
[data-filter=".responsive"],
.responsive {
	.thematic-color {
		background: #F23870; } }

[data-filter=".automation"],
.automation {
	.thematic-color {
		background: #42BB5D; } }

[data-filter=".components"],
.components {
	.thematic-color {
		background: #2F55F8; } }

[data-filter=".technologies"],
.conponents {
	.thematic-color {
		background: #FAC130; } }

.tooltip.show {
	opacity: 1 !important; }
.tooltip-inner {
	background: #FFFFFF;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	color: #000000;
	padding: 9px 13px;
	@include text14; }
.bs-tooltip-bottom .arrow:before {
	border-bottom-color: #fff !important; }

.gray-devider {
	height: 1px;
	width: 100%;
	display: block;
	background: #F3F3F3;
	margin: 0.1rem 0 1.3rem; }

.list-dots {
	li {
		position: relative;
		padding-left: 1.2rem;
		margin-bottom: 1.3rem;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0.3rem;
			display: inline-block;
			width: 8px;
			height: 8px;
			background: #F7163E;
			border-radius: 100px; } } }
.form-control {
	@include text16;
	font-size: 15px;
	@include r(991) {
    height: calc(1.5em + 1.35rem + 2px);
    padding: 0.475rem 1.25rem; } }
input, button {
	font-family: $font-primary !important; }


.select-lang {
	&.opened {
		.menu {
			display: block !important; } } }

.program-group {
	margin-bottom: 1.125rem; }
.program-group-list-item__control {
	position: relative; }
.program-select-input {
	position: absolute;
	left: 0;
	top: 0;
	visibility: hidden;
	opacity: 0;
	&:checked + label {
		color: #fff;
		background-color: #ed0973;
		border-color: #ed0973;
		&::before {
			content: attr(data-checked); } } }
.program-select-btn {
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	&::before {
		content: attr(data-static);
		@include text16; } }

.program-item-category {
	display: flex;
	align-items: center;
	&::before {
		content: '';
		width: 5px;
		height: 5px;
		margin-right: .3rem;
		border-radius: 50%;
		background: $primary;
		display: inline-block; } }

.program-item-partner__image {
	height: 40px;
	max-width: 190px;
	img {
		height: 100%;
		object-fit: contain; } }

.program-translate {
	@include text14;
	display: flex;
	align-items: center;
	justify-content: center;
	&-flags {
		margin-right: 7px;
		img {
			max-width: 20px;
			display: inline-block; } } }

.program-group-right {
	display: flex;
	flex-direction: column; }

.program-item-duration {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	// padding-top: 2rem
	flex-grow: 1;
	@include r(767) {
		order: -1;
		margin-bottom: 0.1rem; }

	@include text14;
	svg {
		margin-right: 9px;
		margin-bottom: 0.2rem; } }

.program-item-participant {
	margin-top: .8rem;
	padding-top: 1.2rem;
	border-top: 1px solid #E7E7E7;
	&-item {
		margin-bottom: 1.2em;
		&__image {
			width: 30px;
			min-width: 30px;
			height: 30px;
			border-radius: 50%;
			overflow: hidden;
			img {
				max-width: 100%;
				object-fit: cover; } } } }

.program-speakers-list {
	border-top: 1px solid #E7E7E7;
	padding-top: 1rem; }

.program-speakers-more {
	text-decoration: underline;
	cursor: pointer;
	display: inline-block; }

.filter {
	&-sidebar {
		margin-bottom: 0.8rem;
		button[type="reset"] {
			@include text14;
			border-radius: 15px;
			@include rmin(1200) {
				padding: 4px;
				border-radius: 15px; }
			@include r(1199) {
				border-radius: 35px; }
			@include r(767) {
				padding: 10px 5px; } }
		&-fieldset {
			margin-bottom: 0.5rem; }
		&-content {
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
			padding: 1.2rem 0.9rem 0.8rem; }
		&-title {
			@include text17;
			padding: 0.8rem 0.9rem 0.9rem;
			background: #EEEEEE; } } }

.filter-item {
	position: relative;
	margin-right: 4px;
	display: inline-block;
	margin-bottom: 0;
	input {
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		&:checked + label {
			background: $primary;
			color: #ffffff;
			&::before {
				background: #fff; } } }
	label {
		background: #EEEEEE;
		border-radius: 5px;
		padding: 2px 10px;
		margin-bottom: 0.9rem;
		transition: all .15s ease;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		@include text14;
		&::before {
			content: '';
			width: 5px;
			height: 5px;
			display: inline-block;
			background: $primary;
			border-radius: 50%;
			margin-right: 4px; }
		&:hover {
			background: $primary;
			color: #ffffff;
			&::before {
				background: #fff; } } } }

.filter-room {
	position: relative;
	margin-right: 4px;
	input {
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		&:checked + label {
			background: $primary;
			color: #ffffff; } }
	label {
		background: #EEEEEE;
		border-radius: 5px;
		padding: 5px 15px;
		margin-bottom: 0.6rem;
		transition: all .15s ease;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		@include text14;
		&:hover {
			background: $primary;
			color: #ffffff; } } }

.filter-sidebar-result {
	border-top: 1px solid #E7E7E7;
	padding-top: 1.2rem; }

.program-subscribe {
	background: #FFFFFF;
	border: 1px solid #ED0973;
	border-radius: 5px;
	padding: 1.4rem 0.8rem;
	&-title {
		font-size: 14px;
		line-height: 14px; } }
.form-control-sm {
    height: calc(1.5em + 0.75rem + 2px); }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    font-size: 12px; }

.select2-container--default .select2-selection--multiple {
	@include r(767) {
		border-radius: 10px; } }

.main-contacts {
	padding: 4.4rem 0 2rem;
	@include r(991) {
		padding: 3rem 0 2rem; } }

.header-nav-list-item__link {
	font-weight: bold;
	@include r(1199) {
		font-size: 12px !important; } }
.contacts-mail-list {
	margin-top: 1rem;
	.row .col-md-6:nth-child(2) {
		border-left: 1px solid #eee;
		@include r(767) {
			border-left: 0; }
		.contacts-mail-list__item {
			padding-left: 4.6rem;
			@include r(991) {
				padding-left: 3rem; }
			@include r(767) {
				padding-left: 0; } } } }

.contacts-mail-list__item {
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 3rem 0;
	@include r(991) {
		padding: 2rem 0; }
	@include r(767) {
		padding: 1.5rem 0; }
	a {
		@include text35;
		text-transform: uppercase;
		text-decoration: underline; } }

.sticky-sidebar {
	top: 5.5rem;
	left: 0;
	position: sticky;
	@include r(1199) {
		position: static; }
	max-height: calc(100vh - 5.5rem);
	overflow-y: auto {
  scrollbar-width: thin; }
	-ms-overflow-style: none;
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: none !important;
		background-color: transparent !important; }
	&::-webkit-scrollbar {
		width: 6px !important;
		background-color: transparent; }
	&::-webkit-scrollbar-thumb {
		background-color: #ed0973; } }
.program-subscribe {
	@include r(1199) {
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background: #ffffff;
		margin-bottom: 0 !important;
		width: calc(100% - 120px);
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease; }
	@include r(991) {
		width: calc(100% - 100px); }
	@include r(767) {
		width: calc(100% - 30px); } }


.program-group-list-item--special {
	position: relative;
	&:before {
		content: '';
		width: 4px;
		height: 100%;
		background: #ED0973;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0; }
	a.text-black {
		color: #ED0973 !important;
		font-weight: bold; } }

.program-item-partner__image {
	@include r(767) {
		height: auto;
		max-width: 70px;
		img {
			height: auto;
			max-width: 100%; } } }

@include r(991) {
	.header-nav-list-item__link {
		@include text12;
		margin-right: 0.6rem !important; }

	.header-nav-list-item {
		&:last-child a {
			margin-right: 0 !important; } }
	.header-logotype {
		max-width: 35px;
		img {
			max-width: 100%; } } }
.js-select {
	@include r(1199) {
    height: 35px!important;
    padding: 5px 10px; }
	option {
		padding: 3px 0; } }

.headerPin {
	padding-top: 75px;
	.header-fixed {
		visibility: visible !important;
		opacity: 1 !important;
		top: 0 !important;
		transition: none !important; } }


.cookies-warn {
	position: fixed;
	bottom: 30px;
	left: 0;
	width: 100%;
	z-index: 50;
	display: none;
	&.waiting {
		display: block; } }
.cookies-modal {
	max-width: 480px;
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	margin: 0 auto;
	padding: 24px 20px; }


.page-error {
	min-height: 85vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center; }

.error {
	&-head {}
	&-text {
		text-transform: uppercase; }
	&-control {
		max-width: 330px;
		margin: 0 auto;
		@include r(767) {
			max-width: 260px; } } }
.program-select-btn:hover {
	color: #fff;
	background-color: #ed0973;
	border-color: #ed0973; }
.program-select-btn:hover,
.program-select-btn:active,
.program-select-btn:focus {
	@include r(1199) {
		color: #ed0973;
		background-color: #fff;
		border-color: #ed0973; } }

.in-dark {
	.select2-container--default .select2-selection--single {
		border: 1px solid #ffffff; } }

.select2 {
	font-weight: normal;
	font-size: 14px;
	line-height: 14px;
	.select2-selection--single {
		height: 50px;
		border-radius: 10px; } }
.select2-container--open .select2-dropdown {
	font-size: 14px;
	line-height: 14px; }
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
    width: 50px; }
.select2-container--classic .select2-selection--single .select2-selection__arrow b, .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ed0973 transparent transparent;
	border-style: solid;
	border-width: 8px 7px 0;
	height: 0;
	left: 50%;
	margin-left: -8px;
	margin-top: -4px; }
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b, .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ed0973;
	border-width: 0 7px 8px; }
.select2-container .select2-selection--single .select2-selection__rendered {
	line-height: 50px;
	padding-left: 20px;
	padding-right: 50px; }
.select2-dropdown {
	background-color: #fff;
	border: none;
	box-shadow: 0px 6px 8px -4px rgba(0,0,0,0.2); }
.select2-results__option {
	padding: 10px 20px 10px; }

.no-image {
	object-fit: contain;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%; }

@include r(1199) {
	.filter-item label:hover, .filter-room label:hover {
		background: #eee;
		color: #000;
		&::before {
			background: #ed0973; } }
	.filter-item label::before {
		margin-right: 8px; }
	.filter-item label {
    padding: 5px 10px;
    margin-bottom: 0.6rem; } }

.simple-slider {
	overflow: visible !important;
	.swiper-slide {
		height: unset; } }
.simple-slider-pagination {
	padding: 1.5rem 0 0; }

.widget {
	margin: 20px 0; }

.placead-item {
	img, video, iframe {
		max-width: 100%;
		width: 100%;
		max-height: 100%;
		object-fit: cover;
		height: 150px;
		overflow: hidden; } }
.bg-grayed {
	background: #F5F5F5; }

.headerPin {
  .main {
    padding-top: 10px; }
		padding-bottom: 50px; }

.hidden {
	display: none; }

textarea.form-control {
	max-height: 145px;
	resize: none; }


.white-popup-block {
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	max-width: 480px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	padding: 2.5rem 1.9rem;
	@include r(565) {
		padding: 2.2rem 1.4rem; } }

label.error {
	color: #ed0973;
	@include text14;
	margin: 5px 0; }

a.text-black {
	color: #000000;
	&:hover {
		opacity: 0.7; } }

// popup gallery
.illustration-slider {
	.swiper-wrapper {
		margin-bottom: 20px; }
	&__item {
		display: block;
		height: 100%; }
	&__image {
		border-radius: 10px;
		width: 100%;
		height: 100%;
		object-fit: cover; } }
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	/* ideally, transition speed should match zoom duration */
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0; }

// play video

.video-slider {
	.swiper-wrapper {
		margin-bottom: 2rem; }
	iframe, video {
		object-fit: cover;
		width: 100%;
		background: #000000; }
	&-slide {
		height: 100%;
		display: flex;
		flex-direction: column; } }
.video-player, .video-embeded {
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	height: 460px; }
.video-player {
	.play-video {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		overflow: hidden;
		cursor: pointer;
		opacity: 1;
		transition: opacity .3s ease; }

	&.play {
		.play-video {
			opacity: 0; } } }

.section {
	padding: 5rem 0 4rem;
	&-white {
		background: #ffffff; }
	&-gray {
		background: #f5f5f5; } }

.header-fixed .header-nav-list-item__link {
    margin-right: 1.5rem; }

.simple-item {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 10px;
  padding: 1.2rem 0.8rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100% - 20px);
  margin-bottom: 20px; }

// lifehack for widgets
.container-fluid {
	.container-fluid {
		padding-left: 0;
		padding-right: 0; } }

.link-special {
	background: #ffffff;
	color: #ed0973 !important;
	position: relative;
	z-index: 1;
	&::after {
		content: '';
		position: absolute;
		left: -4px;
		top: -5px;
		width: calc(100% + 8px);
		height: calc(100% + 8px);
		padding: 6px;
		background: #fff;
		z-index: -1;
		border-radius: 5px; }
	&:hover {
		color:  #ed0973 !important; } }
