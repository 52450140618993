// +font('SFUIDisplay', 'SFUIDisplay-Regular', normal)
// +font('SFUIDisplay', 'SFUIDisplay-Bold', bold)
// +font('SFUIDisplay', 'SFUIDisplay-Black', 900)
@include font('HelveticaNeueCyr', 'HelveticaNeueCyr-Roman', normal);
@include font('HelveticaNeueCyr', 'HelveticaNeueCyr-Bold', bold);
@include font('HelveticaNeueCyr', 'HelveticaNeueCyr-Black', 900);

$font-primary: 'HelveticaNeueCyr';


@mixin text200 {
  font-size: 200px;
  line-height: 100%;
  font-weight: bold;
  @include r(767) {
    font-size: 120px;
    line-height: 100%; } }
// =text100
//   font-size: 100px;
//   line-height: 100px;
// =text90
//   font-size: 90px;
//   line-height: 107px;
//   font-weight: 900
@mixin text47 {
  font-size: 47px;
  line-height: 57px;
  @include r(1199) {
    font-size: 35px;
    line-height: 45px; }
  @include r(991) {
    font-size: 24px !important;
    line-height: 34px !important; } }
@mixin text45 {
  font-size: 45px !important;
  line-height: 51px !important;
  @include r(1199) {
    font-size: 35px !important;
    line-height: 41px !important; }
  @include r(767) {
    font-size: 24px !important;
    line-height: 34px !important; } }
// =text50
//   font-size: 50px;
//   line-height: 57px;
@mixin text40 {
  font-size: 40px;
  line-height: 48px;
  @include r(1199) {
    font-size: 30px;
    line-height: 115%; }
  @include r(991) {
    font-size: 25px;
    line-height: 31px; }
  @include r(767) {
    font-size: 20px;
    line-height: 26px; } }
@mixin text35 {
  font-size: 35px;
  line-height: 41px;
  @include r(991) {
    font-size: 25px;
    line-height: 31px; }
  @include r(767) {
    font-size: 20px;
    line-height: 26px; } }
@mixin text30 {
  font-size: 30px;
  line-height: 115%;
  @include r(767) {
    font-size: 20px;
    line-height: 115%; } }
@mixin text28 {
  font-size: 28px;
  line-height: 33px;
  @include r(767) {
    font-size: 20px;
    line-height: 20px; } }
// =text26
//   font-size: 26px;
//   line-height: 140%;
// =text25
//   font-size: 25px !important
//   line-height: 140% !important
@mixin text24 {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.2px;
  @include r(767) {
    font-size: 20px;
    line-height: 24px; } }
@mixin text22 {
  font-size: 22px;
  line-height: 120%;
  @include r(1199) {
    font-size: 15px;
    line-height: 18px; } }
@mixin text20 {
  font-size: 20px;
  line-height: 25px;
  @include r(1199) {
    font-size: 16px !important;
    line-height: 22px !important; }
  @include r(767) {
    font-size: 18px !important;
    line-height: 24px !important; } }
@mixin text18 {
  font-size: 18px;
  line-height: 21px;
  @include r(1199) {
    font-size: 16px;
    line-height: 21px; }
  @include r(991) {
    font-size: 14px;
    line-height: 17px; }
  @include r(767) {
    font-size: 12px;
    line-height: 14px; } }
@mixin text17 {
  font-size: 17px;
  line-height: 140%;
  @include r(1199) {
    font-size: 15px;
    line-height: 18px; } }
@mixin text16 {
  font-size: 16px;
  line-height: 22px;
  @include r(991) {
    font-size: 14px;
    line-height: 17px; } }
@mixin text15 {
  font-size: 15px;
  line-height: 18px;
  @include r(1199) {
    font-size: 14px;
    line-height: 21px; } }
@mixin text14 {
  font-size: 14px;
  line-height: 21px;
  @include r(991) {
    font-size: 12px;
    line-height: 14px; } }
@mixin text12 {
  font-size: 12px;
  line-height: 12px; }




h1 {
  font-size: 30px;
  line-height: 115%;
  font-weight: 900 !important;
  @include r(767) {
    font-size: 21px !important;
    line-height: 115% !important; } }
h2,h3,h4 {
  font-weight: bold !important; }

.h1 {
  text-transform: uppercase;
  font-weight: 900 !important;
  line-height: 1.03 !important; }
.h2 {
  @include text45;
  text-transform: uppercase; }
.h3 {
  @include text24;
  @include r(991) {
    font-size: 18px !important;
    line-height: 21px !important; } }
.content {
  p {
    @include text16; } }


.text200 {
  @include text200; }
// .text100
//   +text100
// .text90
//   +text90
// .text50
//   +text50
.text45 {
  @include text45; }
.text40 {
  @include text40; }
.text35 {
  @include text35; }
.text30 {
  @include text30; }
.text28 {
  @include text28; }
.text24 {
  @include text24; }
// .text26
//   +text26
.text22 {
  @include text22; }
.text20 {
  @include text20; }
.text18 {
  @include text18; }
.text16 {
  @include text16; }
.text15 {
  @include text15; }
.text14 {
  @include text14; }
.text12 {
  @include text12; }
