.main-partner-slide {
    max-width: 250px;
    &__block {
        width: 100%;
        height: 150px;
        background: #f8f8f8;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center; }
    &__image {
        max-width: 210px;
        max-height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto; } } }
.main-partners {
    padding: 2.7rem 0 4.6rem;
    overflow: hidden;
    .swiper-container {
        overflow: visible !important; }
    .swiper-wrapper {
        align-items: flex-end;
        margin-bottom: 2.5rem; } }
.swiper-pagination {
    position: relative; }
.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 100px;
    background: transparent;
    opacity: 1;
    margin: 0 6px !important;
    transition: all .2s ease;
    position: relative;
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
        border-radius: 100px;
        transition: background .2s ease; } }
.swiper-pagination-bullet-active {
    transform: scale(1.5);
    position: relative;
    &::before {
        background: #ee2360; } }
.container-fluid {
    max-width: 1600px;
    padding-left: 100px;
    padding-right: 100px;
    @include r(1199) {
        padding-left: 60px;
        padding-right: 60px; }
    @include r(991) {
        padding-left: 50px;
        padding-right: 50px; }
    @include r(767) {
        padding-left: 15px;
        padding-right: 15px; } }
.hr-short {
    margin-left: 0;
    max-width: 77%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include r(991) {
        margin-top: 1.2rem;
        margin-bottom: 1.3rem; }
    @include r(767) {
        max-width: 100%; } }
.hero {
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: 100vh;
    max-height: 700px;
    position: relative;
    overflow: hidden;
    padding: 9rem 0 7rem;
    @include r(1199) {
        padding: 7rem 0 5rem;
        min-height: auto; }
    @include r(991) {
        padding-bottom: calc(150px + 5rem); }
    @include r(767) {
        padding: 4.2rem 0 2rem; }
    &-logo {
        max-width: 105px;
        img {
            max-width: 100%; }
        font-weight: 900;
        text-transform: uppercase; }
    &-head {
        @include text17; }
    &-title {
        @include text47;
        font-weight: 900;
        text-transform: uppercase; }
    &-desc {
        @include text17; }
    &-wrapper {}

    &-grid {
        position: absolute;
        right: 0;
        top: 0;
        display: grid;
        height: 100%;
        max-height: {}
        width: 41.95%;
        float: right;
        background: #ddd;
        grid-gap: 0;
        grid-template-columns: repeat(auto-fit, minmax(50%, 34%));
        grid-auto-rows: 33.333333%;
        grid-auto-flow: dense;
        @include r(991) {
            bottom: 0;
            top: auto;
            height: 150px !important;
            width: 100%;
            display: flex; }
        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            @include r(991) {
                width: 25%;
                &:nth-child(3), &:nth-child(5) {
                    display: none; } }
            img {
                object-fit: cover;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                &:nth-child(2) {
                    opacity: 0; } }
            &--logotype {
                img {
                    max-width: 120px;
                    max-height: 100px;
                    object-fit: contain;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    @include r(1199) {
                        max-width: 96px; } } } } } }
.row:not(.no-gutters) {
    width: calc(100% + 20px); }
.main {
    &-about {
        padding: 2.4rem 0 4.25rem;
        @include r(1199) {
            padding: 2.2rem 0 4.25rem; }
        @include r(991) {
            padding: 2rem 0 4.25rem; }
        @include r(767) {
            padding-top: 1.3rem;
            padding-bottom: 3rem; }
        &-partners {
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 2.6rem;
            &__group {
                margin-bottom: 2.4rem;
                @include r(991) {
                    margin-bottom: 1.7rem; }
                @include r(767) {
                    margin-bottom: 1.2rem; } }
            &__title {
                padding-bottom: 10px;
                font-size: 11px;
                line-height: 15px;
                @include r(991) {
                    text-align: center; } }
            &__logos {
                display: flex;
                align-items: center;
                height: 80px;
                @include r(1199) {
                    height: 60px; }
                @include r(991) {
                    justify-content: center; }
                @include r(767) {
                    flex-wrap: wrap;
                    height: auto; }
                img {
                    max-width: 287px;
                    max-height: 100%;
                    margin-right: auto;
                    @include r(1199) {
                        max-width: 237px; }
                    @include r(991) {
                        margin: 0 auto; }
                    @include r(767) {
                        margin-bottom: 1.4rem; } } }
            .col-md-6 {
                .main-about-partners {
                    &__group {
                        padding-left: 54px;
                        border-left: #e5e5e5 1px solid;
                        @include r(991) {
                            padding: 0;
                            border: none; } }
                    &__logos {
                        height: 65px;
                        img {
                            max-width: 100%; }
                        @include r(1999) {
                            height: 45px; } }
                    &__title {
                        height: 40px; } } }
            @include r(991) {
                margin-bottom: 1.8rem; }
            @include r(767) {
                text-align: center;
                margin-bottom: 1.4rem; } }
        &-subscribe {
            border: 1px solid #ed0973;
            border-radius: 15px;
            padding: 2rem 2.4rem;
            @include r(1199) {
                padding: 1.5rem 1.7rem; }
            @include r(991) {
                padding: 1rem 1.2rem;
                border-radius: 10px; }
            @include r(767) {
                padding: 1.3rem 1.2rem; } } }
    &-program {
        background: #f5f5f5;
        padding: 4rem 0 1rem;
        position: relative;
        z-index: 11;
        @include r(1199) {
            padding: 3rem 0 1rem; } } }
.partner-item {
    display: flex;
    align-items: center;
    @include r(767) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
    img {
        max-height: 75px;
        @include r(1199) {
            max-height: 65px; }
        @include r(991) {
            max-height: 55px; }
        @include r(767) {
            max-height: 52px;
            margin-bottom: 1rem; } } }
.program {
    &-group {
        &-head {
            padding: 0.7rem 1.3rem 0.3rem;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d7d7d7; }
        &-list {
            &-item {
                padding: 1.3rem 1.3rem 0.3rem;
                &:not(:last-child) {
                    border-bottom: 3px solid #d7d7d7; } } } } }
.banner {
    background: #f5f5f5; }
.main-showroom {
    padding: 3.6rem 0;
    background: #f5f5f5; }
.showroom-filter {
    margin: 10px 0; }
.showroom-list {
    margin: 30px 0 10px; }
.showroom-list-item {
    display: block;
    color: #000 !important;
    margin-bottom: 20px;
    position: relative;
    height: 390px;
    overflow: hidden;
    @include r(1199) {
        height: 310px; }
    @include r(575) {
        height: auto; }
    &:hover {
        .showroom-list-item-content {
            width: 100%;
            margin-top: -170px;
            overflow-y: auto;
            max-height: 100%;
            @include r(1199) {
                margin-top: -130px; }
            @include r(575) {
                margin-top: 0 !important; } }
        .showroom-list-item__desc {
            height: auto;
            visibility: visible;
            opacity: 1; } }
    background: #fff;
    &__image {
        height: 170px;
        width: 100%;
        object-fit: cover;
        @include r(1199) {
            height: 130px; } }
    &-content {
        position: relative;
        right: 0;
        top: 0;
        background: #fff;
        width: 100%;
        padding: 15px;
        transition: all .6s ease; }
    &__logo {
        max-width: 110px;
        max-height: 40px;
        object-fit: contain;
        margin-bottom: 19px; }
    &__title {
        margin-bottom: 10px; }
    &__desc {
        visibility: hidden;
        opacity: 0;
        transition-delay: 3s;
        transition: all .6s ease;
        overflow: hidden;
        height: 0;
        @include r(575) {
            opacity: 1 !important;
            visibility: visible !important;
            height: auto !important; } } }
.showroom-list-item-lead {
    color: #000 !important;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    overflow: hidden;
    height: 390px;
    @include r(1199) {
        height: 310px; }
    @include r(575) {
        width: 100%;
        height: auto;
        display: block; }
    &:hover {
        .showroom-list-item-lead-content {
            width: 100%; }
        .showroom-list-item-lead__desc {
            width: auto;
            visibility: visible;
            opacity: 1; } }
    &__image {
        width: 50%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
        transition: all .6s ease;
        @include r(575) {
            width: 100%;
            height: 250px; } }
    &-content {
        position: absolute;
        right: 0;
        top: 0;
        background: #fff;
        width: 50%;
        height: 100%;
        padding: 20px;
        transition: all .6s ease;
        @include r(575) {
            width: 100%;
            position: static;
            height: auto;
            padding: 15px; } }
    &__logo {
        max-width: 110px;
        max-height: 50px;
        object-fit: contain;
        margin-bottom: 1.1rem;
        @include r(575) {
            max-height: 40px;
            margin-bottom: 0.8rem; } }
    &__title {
        margin-bottom: 10px; }
    &__desc {
        visibility: hidden;
        opacity: 0;
        transition: all .6s ease;
        max-height: 100%;
        @include r(575) {
            opacity: 1 !important;
            visibility: visible !important; } } }
.main-networking {
    padding: 4rem 0 0;
    overflow: hidden;
    @include r(767) {
        padding: 2rem 0 0; } }
.networking {
    &-slider {}
    .swiper-container {
        overflow: visible !important;
        &-slide {
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            height: 100%;
            display: flex;
            flex-direction: column;
            &__image {
                height: 200px;
                width: calc(100% + 2px);
                object-fit: cover;
                object-position: center;
                margin: -1px -1px 0 -1px;
                @include r(1300) {
                    height: 171px; } }
            &-content {
                padding: 1rem 1.3rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                @include r(767) {
                    padding: 0.8rem 0.9rem; } }
            &__company {
                max-height: 55px;
                max-width: 100%;
                object-fit: contain;
                margin-bottom: 10px;
                @include r(767) {
                    max-height: 44px; } }
            &__name {
                margin-bottom: 4px; }
            &__desc {
                margin-bottom: 50px;
                flex: 1;
                @include r(767) {
                    margin-bottom: 30px; } }
            &__control {
                margin-top: auto; } }
        .swiper-slide {
            height: auto !important; }
        .swiper-pagination {
            padding: 44px 0 0;
            @include r(767) {
                padding-top: 30px; } } } }
.main-speakers {
    padding: 3.5rem 0 1.8rem; }
.speakers-slider-big {
    overflow: hidden;
    position: relative;
    margin: 15px 0;
    .speakers-slider-big-next,
    .speakers-slider-big-prev {
        position: absolute;
        height: calc(100% - 50px);
        width: 140px;
        top: auto;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
        opacity: 0;
        transition: opacity .3s ease;
        &:hover {
            opacity: 1; }
        &:after {
            content: none; } }
    .speakers-slider-big-next {
        right: 0; }
    .speakers-slider-big-prev {
        left: 0;
        justify-content: flex-start;
        svg {
            transform: rotate(180deg); } }
    .swiper-slide {
        max-width: 530px;
        @include r(575) {
            max-width: 259px; } } }
.swiper-speakers-slider-small,
.swiper-speakers-slider-big {
    overflow: visible !important; }
.speakers-slider-big-slide {
    height: 330px;
    background: #ed0973;
    color: #fff;
    padding: 40px 15px 50px 0;
    @include r(575) {
        height: 456px;
        padding: 15px 15px 0 0; } }
.speakers-slider-big {
    .swiper-slide {
        padding-top: 50px; }
    .speakers-slider-big-slide__figure {
        margin-top: -90px;
        margin-right: -160px;
        img {
            height: 380px;
            max-width: 100%;
            object-fit: contain;
            object-position: left bottom;
            filter: grayscale(1); } }
    .speaker-slider-big-slide__info {
        padding-left: 1rem;
        max-width: 300px; }
    .speaker-slider-big-slide__name {
        font-size: 32px;
        line-height: 120%;
        text-transform: uppercase;
        font-weight: bold;
        margin: 10px 0; }
    .speaker-slider-big-slide__description {
        margin: 10px 0; } }
.speakers-slider-small {
    overflow: hidden;
    position: relative;
    margin: 20px 0;
    .swiper-wrapper {
        margin-bottom: 2.5rem;
        @include r(767) {
            margin-bottom: 2rem; } }
    .swiper-slide {
        max-width: 160px;
        @include r(767) {
            max-width: 122px; } }
    .speakers-slider-small-slide__name {
        white-space: normal;
        @include r(767) {
            white-space: unset; } }
    .speakers-slider-small-next,
    .speakers-slider-small-prev {
        position: absolute;
        height: 160px;
        @include r(767) {
            height: 122px; }
        width: 140px;
        top: 22px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
        opacity: 0;
        transition: opacity .3s ease;
        &:hover {
            opacity: 1; }
        &:after {
            content: none; } }
    .speakers-slider-small-next {
        right: 0; }
    .speakers-slider-small-prev {
        left: 0;
        justify-content: flex-start;
        svg {
            transform: rotate(180deg); } }
    &-slide {
        max-width: 160px;
        &__figure {
            border-bottom: 10px solid #f23870;
            img {
                width: 100%;
                height: 160px;
                object-position: center center;
                object-fit: cover;
                filter: grayscale(1);
                @include r(767) {
                    height: 122px; } } } } }
.main-news {
    padding: 4.5rem 0 2rem; }
.news-list {
    margin: 6px 0;
    &-item {
        position: relative;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: calc(100% - 20px);
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        transition: border .3s ease;
        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.2);
            &::before {
                height: 14px;
                opacity: 0.9; } }
        &::before {
            content: '';
            position: absolute;
            left: -1px;
            top: -1px;
            height: 10px;
            width: calc(100% + 2px);
            transition: all .2s ease;
            background: #f23870; }
        &__figure {
            height: 170px;
            width: calc(100% + 2px);
            margin-left: -1px;
            background: #f23870;
            display: flex;
            align-items: center;
            justify-content: center; }
        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        &-content {
            padding: 23px 20px 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-grow: 1; }
        &-title {
            margin-bottom: 1rem;
            flex-grow: 1;
            color: #000; } } }
@include r(575) {
    .speakers-slider-big .speakers-slider-big-slide__figure {
        margin-top: 0;
        margin-right: 0; }
    .speakers-slider-big .speakers-slider-big-next, .speakers-slider-big .speakers-slider-big-prev {
        width: auto; }
    .speakers-slider-big .speakers-slider-big-slide__figure img {
        height: 277px;
        max-width: none; }
    .speakers-slider-big .swiper-slide {
        overflow: hidden; }
    .speakers-slider-big .speakers-slider-big-slide__figure {
        position: absolute;
        bottom: 0;
        left: 0; }
    .speakers-slider-big .speaker-slider-big-slide__name {
        font-size: 28px; }
    .speakers-slider-big-next,
    .speakers-slider-big-prev,
    .speakers-slider-small-next,
    .speakers-slider-small-prev,
    svg {
        max-width: 30px; }
    .speakers-slider-big .swiper-slide {
        padding-top: 0; }
    .main-contacts {
        padding: 1.5rem 0 1rem; }
    .main-speakers {
        padding: 1.5rem 0 1rem; } }
// helpers
.op05 {
    opacity: 0.5; }
.btn-show {
    padding: 1.275rem 1.25rem;
    color: #f23870; }
.filter-fieldset {
    position: relative; }
.reset-filter-showroom {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 11;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
    svg {
        max-width: 20px;
        max-height: 20px; } }
.program-group {
    transition: transform 299ms ease 0ms, opacity 299ms linear 0ms;
    opacity: 1;
    transform: translate(0px, 0);
    &.group-hide {
        transform: translateY(8%);
        opacity: 0;
        position: absolute !important;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        overflow: hidden; } }
@include r(991) {
    .program-item-duration svg {
        margin-bottom: 0 !important; } }
