html, body {
  scroll-behavior: smooth; }

.new-comment {
  color: red; }

.program-subscribe {
  z-index: 99;
  &.hidden {
    display: none; } }

.program-sending-title {
  &.hidden {
    display: none; } }

.form-block {
  &.hidden {
    display: none; } }

.select-lang.ui .menu > .item {
  color: #000000 !important;

  &.active {
    color: rgba(0, 0, 0, 0.3) !important; } }

.big-block, .small-block {
  &.hidden-block {
    display: none; } }

.illustration-slider {
  &__image {
    max-height: 490px; } }

.mfp-title {
  display: none; }

.stub-image {
  width: 100%; }
