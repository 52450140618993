.icon-arrowBack {
  width: 0.65em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-user {
  width: 0.86em;
  height: 1em;
  fill: initial;
}
