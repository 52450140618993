// +r(1140)
//   .hero-grid
//     position: relative
//     width: 100%
//     &:nth-child(5)
//     &:nth-child(6)
//       display: none

@include r(991) {
  .header-nav-list-item__link {
    margin-right: .9rem; }
  .btn {
    padding: 0.575rem 0.85rem;
    font-size: 1rem; } }
@include r(767) {
  .header-nav, .hero-grid {
    display: none; } }
