.expert-card-about {
    &__company {
        max-height: 40px;
        max-width: 100%;
        object-fit: contain;
        margin-bottom: 10px;
        @include r(767) {
            max-height: 44px; } } }

.expert-callback {
    width: 100%;
    max-width: 288px;
    @include r(565) {
        max-width: 100%; }
    &-list {
        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            color: #fff !important;
            img {
                margin-right: 9px;
                width: 26px;
                height: 26px;
                min-width: 26px;
                object-fit: contain; }
            &:first-child {
                padding-top: 5px; }
            &:last-child {
                padding-bottom: 6px;
                border-bottom: 0; } } } }

.expert-callback-more.opened {
    background: #ed0973 !important; }

.expert-card-about__photo {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    overflow: hidden;
    @include r(767) {
        max-width: 150px;
        margin-bottom: 2rem; } }

.go-back-link {
    display: inline-flex;
    svg {
        margin-right: 5px; } }

.document {
    &-widget {
        padding-top: 5rem; }
    &-item {
        &__icon {
            width: 35px;
            height: 35px;
            object-fit: contain;
            margin-bottom: 1rem; }
        &__name {
            margin-bottom: 1.5rem; }
        &__download {
            margin-top: auto;
            display: inline-block;
            color: #1338b9;
            &:hover {
                color: #ed0973 !important; } } } }

.stand {
    &-partner {
        &-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            &__logo {
                width: 143px;
                height: 37px;
                object-fit: contain;
                margin-right: 15px; }
            &__sponsor {
                width: 53px;
                height: 37px;
                object-fit: contain; } } } }

.stand-reward {
    &__image {
        max-width: 89px;
        height: 54px;
        object-fit: contain; } }

.stand-partner__title {
    flex-grow: 1; }

.stand-about {
    z-index: 1; }

.stand-background {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    max-width: 100%;
    img {
        max-width: 100%;
        object-fit: contain; } }

.sidebar {
    background: #ed0973;
    border-radius: 10px;
    padding: 1.6rem;
    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        color: #fff !important;
        img {
            margin-right: 9px;
            width: 26px;
            height: 26px;
            min-width: 26px;
            object-fit: contain; }
        &:first-child {
            padding-top: 5px; }
        &:last-child {
            padding-bottom: 6px;
            border-bottom: 0; } } }

.sidebar-links {
    border-color: rgba(255, 255, 255, 0.5) !important; }

.other-programs-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important; }

.top-nav {
    position: relative;
    transition: background .2s ease;
    width: 100%;
    &.sticky {
        position: fixed;
        top: 0 !important;
        background: #f0f0f0;
        .top-nav-wrapper {
            border-bottom: 0;
            margin-top: 0 !important; } }
    z-index: 39;
    &-wrapper {
        position: relative;
        z-index: 2;
        //margin-top: -47px
        height: 47px;
        box-sizing: border-box;
        background: #f0f0f0;
        border-radius: 10px; }
    &-list {
        margin-left: -8px;
        margin-right: -8px;
        padding: 12px 0;
        margin-bottom: 0;
        &-item {
            padding-left: 8px;
            padding-right: 8px;
            a {
                color: #000;
                &:hover {
                    color: #ed0973; } } } } }

.stand-company-logos__item {
    width: 193px;
    height: 72px;
    object-fit: contain; }

.stand-partners-slider {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }

.stand-hero {
    min-height: 470px;
    position: relative;
    z-index: 1;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    &-partner {
        min-height: 800px;
        @include r(1599) {
            min-height: 630px; } }
    overflow: hidden;
    //padding: 7rem 0 9rem
    padding: 1.6rem 0;
    @include r(767) {
        padding: 4.2rem 0 2rem; }
    &-logo {
        max-width: 105px;
        img {
            max-width: 100%; } }
    &-title {
        font-weight: 900;
        text-transform: uppercase; }
    &-wrapper {
        background: #fff;
        border-radius: 20px;
        padding: 1.3rem 2.4rem;
        max-width: 622px; }

    &-grid {
        position: absolute;
        right: 0;
        top: 3%;
        display: grid;
        height: 85%;
        max-height: {}
        width: 35.95%;
        float: right;
        background: #ddd;
        grid-gap: 0;
        grid-template-columns: repeat(auto-fit, minmax(50%, 34%));
        grid-auto-rows: 33.333333%;
        grid-auto-flow: dense;
        @include r(991) {
            display: none;
            bottom: 0;
            top: auto;
            height: 150px !important;
            width: 100%; }
        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            @include r(991) {
                width: 25%;
                &:nth-child(3), &:nth-child(5) {
                    display: none; } }
            img {
                object-fit: cover;
                object-position: top center;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                &:nth-child(2) {
                    opacity: 0; } }
            &--logotype {
                img {
                    max-width: 120px;
                    max-height: 100px;
                    object-fit: contain;
                    left: 50%;
                    top: 50%;
                    width: auto !important;
                    height: auto !important;
                    transform: translate(-50%, -50%);
                    @include r(1199) {
                        max-width: 96px; } } } } } }

@include r(1199) {
    .stand-partner-head__logo {
        width: 115px; }
    .stand-partner-head__sponsor {
        width: 45px;
        height: 32px; }
    .stand-reward__image {
        max-width: 80px;
        height: 48px; }
    .stand-partner-reward__item {
        .text14 {
            line-height: 16px; } } }

@include r(991) {
    .stand-company-logos__item {
        width: 140px;
        height: 54px; }
    .top-nav-list {
        margin-left: -3px;
        margin-right: -3px;
        flex-wrap: wrap; }
    .top-nav-list-item {
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 7px; }
    .top-nav-list {
        padding: 17px 0.8rem 12px !important; }
    .video-embeded, .video-player {
        height: 360px !important; } }

@include r(767) {
    .top-nav-wrapper {
        margin-top: 0;
        height: auto; }
    .top-nav-list {
        margin-left: -6px;
        margin-right: -6px;
        font-size: 14px;
        line-height: 16px; }
    .top-nav-list-item {
        padding-left: 6px;
        padding-right: 6px;
        margin-bottom: 9px; }
    .stand-hero {
        min-height: auto;
        padding: 2rem 0 2.5rem; }
    .stand-hero-wrapper {
        padding: 1rem 1.1rem;
        .text45 {
            font-size: 20px !important;
            line-height: 26px !important; } }
    .stand-company-logos__item {
        width: 108px;
        height: 42px; }
    .stand-company-logos {
        margin-bottom: 0.5rem; }
    .document-widget {
        padding-top: 1rem; }
    .section {
        padding: 1rem 0 2rem !important; }
    .video-embeded, .video-player {
        height: 250px !important; } }

.stand-hero-grid-item__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff !important;
    z-index: 1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    &:nth-child(1) {
        z-index: 1;
        opacity: 1; }
    &:nth-child(2) {
        opacity: 0;
        z-index: 0; }
    &::before {
        content: '';
        position: absolute;
        z-index: 2;
        background: #f23870;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        display: inline-block;
        transition: height .2s ease; }
    &:after {
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 100%);
        transition: opacity .3s ease;
        opacity: 0.5; }
    &:hover {
        &:before {
            height: 10px; }
        &::after {
            opacity: 0.65; } } }

.stand-hero-grid-item--logotype .stand-hero-grid-item__link:after {
    content: none !important; }

.stand-hero-grid-item__content {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: block;
    span {
        display: block; } }

.stand-hero-grid-item__desc {
    font-size: 12px;
    line-height: 14px; }


/* New styles */
.stand {
    &-small {
        &__bg {
            width: 1920px;
            height: 1080px;
            position: fixed;
            margin-right: -487px;
            right: calc(50% - 1420px / 3);
            top: 0;
            object-position: center top;
            object-fit: cover;
            z-index: -1;
            @include r(1439) {
                right: calc(50% - (100% - 180px) / 3); }
            @include r(1199) {
                right: calc(50% - (100% - 100px) / 3); }
            @include r(991) {
                right: calc(50% - (100% - 80px) / 3); }
            @include r(767) {
                left: 0; } } } }
#about {
    padding: 1.6rem;
    background: #fff;
    border-radius: 10px; }

.swiper {
    &-container {
        padding-bottom: 50px; }
    &-pagination {
         &-bullets {
              position: absolute;
              bottom: 0; } } }
.expert {
    &__card {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 24px 22px;
        border: #e6e6e6 1px solid;
        @include r(1439) {
            padding: 13px 19px; }
        .btn {
            width: 307px;
            max-width: 100%;
            margin: 28px auto;
            @include r(1439) {
                width: 193px;
                margin: 20px auto 0; } } }
    &__data {
        display: grid;
        grid-template-columns: 140px auto;
        grid-template-rows: 33px auto auto;
        grid-gap: 10px 30px;
        margin-bottom: auto;
        @include r(1439) {
            grid-template-columns: 88px auto;
            grid-template-rows: 31px auto auto;
            grid-gap: 8px 14px; }
        &-photo {
            grid-column-start: 1;
            grid-row: 1 / span 3;
            height: 140px;
            @include r(1439) {
                height: 88px; }
            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
                border-radius: 50%; } }
        &-company-logo {
            grid-column-start: 2;
            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: contain; } }
        &-name {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 7px;
            @include r(1439) {
                font-size: 16px;
                margin-bottom: 4px; } }
        &-desc {
            font-size: 16px;
            line-height: 1.2;
            color: #a6a6a6;
            @include r(1439) {
                font-size: 14px; } } } }


.photo-report {
    overflow: hidden;
    padding: 4.5rem -0px 2rem; }
#photo-slider {
    .slick {
        &-list {
            overflow: visible; }
        &-slide {
            outline: none !important; } }
    .slide {
        padding: 0 10px;
        @include r(768) {
            img {
                width: 320px;
                height: 320px;
                object-position: center;
                object-fit: contain; } } } }
